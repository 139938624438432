body {
    padding-top: 70px;
    font-family: "Lato", serif;
}


/* text */
.location-text {font-weight: 600;}

/* search */
.search-container {
text-align: center;
    margin-top: 10px;}
.search-box {
    width: 80%;
    max-width: 300px;
    padding: 8px 12px;
    border: 1px solid #ccc;
    border-radius: 20px;
    /* Rounded border */
    outline: none;
    font-size: 16px;
    transition: border-color 0.3s ease;}
.search-box:focus {
    border-color: #3bcfd4;}

.browse-box,
.browse-box-lower {
    margin: 15px;
    text-align: center;
}

.browse-box-lower {
    max-width: 300px;
    font-size: 12px;
    margin: 10px auto;
    color: #EC5800
}

/* galleries */
.highlight-gallery {
    /* max-height: 80vh; */
    width: 325px;
    text-align: center;
    margin: 10px auto;
}

.decade-cover {
width: 100vh
}

.decade-cover-top {
    width: 100%;
}

.decade-grid {
    gap: 10px;
    width: 100%;
    justify-items: center
}

/* .image-grid {
    text-align: center;
    margin: 10px auto;
} */


.image-grid {
    display: grid;
        gap: 5px;
        /* Creates as many columns as fit into the row at min 250px wide */
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        justify-items: center;
        /* Centers each item within its grid cell */
        margin: 5px auto;
        width: 100%;
}


.grid-image {
    width: 80%;
}

/* PIC CONTROLS */
.dots-container {text-align: center; margin-top: 0px;}
/* Styling for each dot */
.dots-container span {
    display: inline-block;
    height: 15px;
    width: 15px;
    margin: 0 5px;
    background-color: #bbb;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s ease;
}
.dots-container span.active { background-color: #717171;}
/* DOWNLOAD */
.download-icon {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background: rgba(0, 0, 0, 0.5);
    padding: 5px;
    border-radius: 50%;
    color: white;
    text-decoration: none;
    transition: background 0.3s;}
.download-icon:hover {background: rgba(0, 0, 0, 0.7);}
.calendar {
    display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 100vh;
}

.calendar p {
    font-size:3em;
    font-weight: 700;
    margin-top: 30px;
}
/* TABLET */
@media screen and (max-width: 1024px)  {
    .decade-cover {    
        width: 100%;}
    .highlight-gallery {
        width: 600px;
        }

}

/* MOBILE */
@media screen and (max-width: 540px) {
     
        .highlight-gallery,
        .decade-cover-top,
        .decade-grid,
        .grid-image {
            width: 100%;
        }
}

/* BIG SCREEN */
/* @media screen and (min-width: 1025px) {

    .image-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
    }
    .grid-image {
        width: 50%;
    }
} */