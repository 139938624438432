/* Footer Menu */

.top-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: linear-gradient(to left, #3bcfd4, #fc9305, #f20094);
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10px 0;
    z-index: 1000;
    height: 50px;
    font-family: "Lato", sans-serif;
}

/* Ensure menu items are styled properly */
.top-menu .menu-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    padding: 10px;
    transition: background 0.3s ease;
}

/* Ensure the text is white, bold, and in Lato */
.top-menu .menu-item .menu-label {
    font-family: "Lato", sans-serif;
    font-weight: 500;
    /* Bold */
    color: white !important;
    /* Ensures white color */
    text-transform: uppercase;
    /* Optional: Makes it more readable */
}

/* Ensures icons in the top menu are white */
.top-menu .menu-item .menu-icon {
    color: white !important;
    fill: white !important;
    stroke: white !important;
    /* Ensures any stroke is white */
    width: 24px;
    /* Adjust size if needed */
    height: 24px;
}

.menu-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    text-align: center;
}

.menu-icon {
    font-size: 24px;
    color: #555;
}

.menu-label {
    font-size: 12px;
    color: #555;
    margin-top: 4px;
}

.menu-item:hover .menu-icon,
.menu-item:hover .menu-label {
    color: #007bff;
}